<template>
  <div class="text-center">
    <p>{{$t(title)}}</p>
    <h2 v-if="kpiData && kpiData.value ">{{ formatValue(kpiData.value)  }}</h2>
    <h2 v-else>{{ formatValue(0) }}</h2>
  </div>
</template>

<script>
import kpiService from '@/$plugins/services/kpi-service'

export default {
  name: 'KpiSalesText',
  props: {
    title: String,
    kpiQueryData: {
      key: String,
      rangeType: String,
      rangeValue: String
    },
    valueFormat: Function
  },
  data () {
    return {
      kpiData: []
    }
  },
  async mounted () {
    const kpiDataResult = await kpiService.getKpiData(this.kpiQueryData.key, this.kpiQueryData.rangeType, this.kpiQueryData.rangeValue)
    if (kpiDataResult) {
      this.kpiData = kpiDataResult[0]
    }
    this.$emit('dataLoaded')
  },
  methods: {
    formatValue (val) {
      if (this.valueFormat) {
        return this.valueFormat(val)
      }
      return val
    }
  }
}
</script>
