import http from '@/$plugins/http'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

export default {
  async getKpiData (key, rangeType, range, top) {
    let urlParams = ''
    if (rangeType) {
      urlParams += `/${rangeType}`
    }
    if (range) {
      urlParams += `/${range}`
    }
    if (top) {
      urlParams += `/${top}`
    }
    const result = await http({
      method: 'get',
      url: `/${STOREMODULE_ENDPOINTMODULE_MAP.reporting}/api/kpi/kpi/${key}${urlParams}`
    })

    if (result.data.success) {
      return result.data.result
    }
    console.log(result.data.errorMessage)
    return null
  }
}
