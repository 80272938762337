<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-auto">{{ $t(title) }}</div>
      <div class="col-auto">
        <b-form-select v-model="currentXAxis" :options="getRangeTypes" @change="reloadGraph()" />
      </div>
    </div>

    <VueApexCharts v-if="isLoaded" width="500" type="line" :options="chartOptions" :series="series"></VueApexCharts>
  </div>
</template>

<script>
import kpiService from '@/$plugins/services/kpi-service'
import moment from 'moment'
import 'moment/locale/de'
import VueApexCharts from 'vue-apexcharts'

const rangeTypes = ['Year', 'Month', 'Week', 'Day']

export default {
  name: 'KpiSalesGraph',
  components: { VueApexCharts },
  props: {
    title: String,
    kpiQueryData: {
      key: String
    },
    valueFormat: Function
  },
  data () {
    return {
      isLoaded: false,
      currentXAxis: 'Week',
      chartOptions: {
        chart: {
          id: `chart-${this.kpiQueryData.key}`,
          width: '100%',
          toolbar: {
            tools: {
              download: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#612680',
            shadeTo: 'light',
            shadeIntensity: 0.6
          }
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              fontFamily: 'Lato'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: 'Lato'
            }
          },
          min: 0,
          max: (max) => Math.max(max, 5),
          forceNiceScale: true,
          decimalsInFloat: 0
        },
        noData: {
          text: this.$t('kpi.graph.nodata')
        },
        tooltip: {
          style: {
            fontFamily: 'Lato'
          },
          y: {
            formatter: (value) => {
              return this.formatValue(value)
            }
          }
        }
      },
      series: [{
        name: '',
        data: []
      }]
    }
  },
  async mounted () {
    await this.reloadGraph()
    this.$emit('dataLoaded')
    this.isLoaded = true
  },
  computed: {
    getRangeTypes () {
      return rangeTypes.map(itm => ({ text: this.$t(`kpi.graph.salesxaxis.${itm.toLowerCase()}`), value: itm }))
    }
  },
  methods: {
    async reloadGraph () {
      const kpiDataResult = await kpiService.getKpiData(this.kpiQueryData.key, this.currentXAxis)
      if (kpiDataResult) {
        const data = kpiDataResult.filter(itm => itm.rangeType === this.currentXAxis)

        this.series = [{
          name: this.$t(`kpi.graph.salesxaxis.${this.currentXAxis.toLowerCase()}`),
          data: data.map(itm => ({ x: this.getRangeValue(itm.rangeValue, itm.rangeType), y: itm.value }))
        }]
      }
    },
    getRangeValue (rangeValue, rangeType) {
      switch (rangeType) {
        case 'Day':
          return moment(rangeValue, 'YYYYMMDD').format('DD.MM.YYYY')
        case 'Month':
          return moment(rangeValue, 'YYYYMM').locale('de').format('MMM YYYY')
        case 'Week':
          return moment(rangeValue, 'YYYYWW').format('WW (YYYY)')
        default:
          return rangeValue.toString()
      }
    },
    formatValue (val) {
      if (this.valueFormat) {
        return this.valueFormat(val)
      }
      return val
    }
  }
}
</script>
