<template>
  <div>
    <hr />
    <div class="row">
      <div class="col-12 col-md-4" v-for="key in Object.keys(partials)" :key="key">
        <component :is="partials[key].partial" @dataLoaded="partials[key].loaded = true" :valueFormat="partials[key].format" :title="partials[key].title" :kpiQueryData="partials[key].data" :class="{ 'd-none': !partials[key].loaded }"></component>
        <Loader v-if="!partials[key].loaded"></Loader>
      </div>
    </div>
    <hr />
    <div class="row ">
      <div class="col-12 col-md-4" v-for="key in Object.keys(graphs)" :key="key">
        <component :is="graphs[key].partial" @dataLoaded="graphs[key].loaded = true" :valueFormat="graphs[key].format" :title="graphs[key].title" :kpiQueryData="graphs[key].data" :class="{ 'd-none': !graphs[key].loaded }"></component>
        <Loader v-if="!graphs[key].loaded"></Loader>
      </div>
    </div>
    <hr />
    <div class="row ">
      <div class="col-12 col-md-4" v-for="key in Object.keys(toptables)" :key="key">
        <component :is="toptables[key].partial" @dataLoaded="toptables[key].loaded = true" :valueFormat="toptables[key].format" :title="toptables[key].title" :kpiQueryData="toptables[key].data" :class="{ 'd-none': !toptables[key].loaded }"></component>
        <Loader v-if="!toptables[key].loaded"></Loader>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Loader from '../Loader.vue'
import KpiSalesGraph from './partials/KpiSalesGraph.vue'
import KpiSalesPie from './partials/KpiSalesPie.vue'
import KpiSalesText from './partials/KpiSalesText.vue'
import KpiSalesTopTable from './partials/KpiSalesTopTable.vue'
export default {
  name: 'KpiDashboard',
  components: {
    Loader,
    KpiSalesText,
    KpiSalesGraph,
    KpiSalesPie,
    KpiSalesTopTable
  },
  data () {
    return {
      partials: {
        KpiDailySales: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.dailysales',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-sale',
            rangeType: 'Day',
            rangeValue: moment().format('YYYYMMDD')
          }
        },
        KpiWeeklySales: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.weeklysales',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-sale',
            rangeType: 'Week',
            rangeValue: moment().format('YYYYWW')
          }
        },
        KpiMonthlySales: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.monthlysales',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-sale',
            rangeType: 'month',
            rangeValue: moment().format('YYYYMM')
          }
        },
        KpiAverageCart: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.averagecart',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-averagecartvalue',
            rangeType: 'all',
            rangeValue: 0
          }
        },
        KpiAverageCartArticleAmount: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.averagecartarticleamount',
          data: {
            key: 'order-averagecartamount',
            rangeType: 'all',
            rangeValue: 0
          }
        },
        KpiVoucherAmount: {
          partial: 'KpiSalesText',
          loaded: false,
          title: 'kpi.partial.voucheramount',
          data: {
            key: 'order-voucheramount',
            rangeType: 'all',
            rangeValue: 0
          }
        }
      },
      graphs: {
        KpiSales: {
          partial: 'KpiSalesGraph',
          loaded: false,
          title: 'kpi.graph.salesper',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-sale'
          }
        },
        KpiOrderAmount: {
          partial: 'KpiSalesGraph',
          loaded: false,
          title: 'kpi.graph.orderamount',
          data: {
            key: 'order-amount'
          }
        },
        KpiPaymentMethods: {
          partial: 'KpiSalesPie',
          loaded: false,
          title: 'kpi.graph.paymentmethod',
          data: {
            key: 'order-paymentmethod'
          }
        },
        KpiArticleVolume: {
          partial: 'KpiSalesGraph',
          loaded: false,
          title: 'kpi.graph.articlevolume',
          data: {
            key: 'order-articlevolume'
          }
        },
        KpiFamilies: {
          partial: 'KpiSalesPie',
          loaded: false,
          title: 'kpi.graph.sortimentgroup',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-family'
          }
        },
        KpiShippingMethods: {
          partial: 'KpiSalesPie',
          loaded: false,
          title: 'kpi.graph.shippingmethods',
          data: {
            key: 'order-shipmentmethod'
          }
        },
        KpiCustomerGroupMethods: {
          partial: 'KpiSalesPie',
          loaded: false,
          title: 'kpi.graph.customergroup',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-customergroup'
          }
        }
      },
      toptables: {
        KpiProductSaleVolume: {
          partial: 'KpiSalesTopTable',
          loaded: false,
          title: 'kpi.toptable.topsalesproducts',
          format: (val) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(val),
          data: {
            key: 'order-productsalevolume',
            top: 30
          }
        },
        KpiProductSaleAmount: {
          partial: 'KpiSalesTopTable',
          loaded: false,
          title: 'kpi.toptable.topsalesproductsamount',
          data: {
            key: 'order-productsaleamount',
            top: 30
          }
        }
      }
    }
  },
  methods: {
    dataLoaded (key) {
      this.partials[key].loaded = true
    }
  }
}
</script>
