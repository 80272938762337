<template>
  <section>
    <h1>{{ $t(`views.${$options.name.toLowerCase()}.title`) }}</h1>
    <KpiDashbaord></KpiDashbaord>
    <hr/>
  </section>
</template>

<script>
import KpiDashbaord from '@/components/kpi/KpiDashboard'

const REQUIRED_ACTION_ENTITY_KEYS = ['users']

export default {
  name: 'Dashboard',
  components: {
    KpiDashbaord
  },
  computed: {
    isRequiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/is`] }), {})
    },
    requiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/getRequiredActions`] }), {})
    }
  },
  methods: {
    getRequiredActivities () {
      REQUIRED_ACTION_ENTITY_KEYS
        .forEach(eKey => {
          this.$store.dispatch(`${eKey}/getRequiredActions`)
        })
    }
  },
  created () {
    this.getRequiredActivities()
  }
}
</script>

<style lang="scss"></style>
