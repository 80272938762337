<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-auto">{{ $t(title) }}</div>
      <div class="col-auto">
        <b-form-select v-model="currentRangeType" :options="getRangeTypes" @change="dropDownChanged()" />
      </div>
      <div class="col-auto" v-if="currentRangeValue !== '0'">
        <ul class="pagination mb-0">
          <li class="page-item"><a href="#" @click.prevent="pagingDate(true)" class="page-link bg-primary text-white">&laquo;</a></li>
          <li class="page-item"><span class="page-link">{{ currentRangeDisplay }}</span></li>
          <li class="page-item" v-if="dateSelector.nextEnabled"><a href="#" @click.prevent="pagingDate(false)" class="page-link bg-primary text-white">&raquo;</a></li>
        </ul>
      </div>
    </div>
    <ul class="list-group list-group-flush" v-if="dataResult.length">
      <li v-for="itm in dataResult" :key="itm.kpiKey" class="list-group-item  d-flex justify-content-between align-items-center">
        {{ itm.metaData }}
        <span>{{ formatValue(itm.value) }}</span>
      </li>
    </ul>
    <div class="text-center" v-else>
      {{ $t('kpi.toptable.nodata') }}
    </div>
  </div>
</template>

<script>
import kpiService from '@/$plugins/services/kpi-service'
import moment from 'moment'
import 'moment/locale/de'

const rangeTypes = ['Year', 'Month', 'Week', 'Day', 'All']

export default {
  name: 'KpiSalesTopTable',
  props: {
    title: String,
    kpiQueryData: {
      key: String,
      top: Number
    },
    valueFormat: Function
  },
  data () {
    return {
      isLoaded: false,
      currentRangeType: 'Week',
      currentRangeValue: '',
      currentRangeDisplay: '',
      currentDate: moment(),
      dateSelector: {
        nextEnabled: false
      },
      dataResult: []
    }
  },
  async mounted () {
    await this.reloadTable()
    this.$emit('dataLoaded')
    this.isLoaded = true
  },
  computed: {
    getRangeTypes () {
      return rangeTypes.map(itm => ({ text: this.$t(`kpi.graph.salesxaxis.${itm.toLowerCase()}`), value: itm }))
    }

  },
  methods: {
    async dropDownChanged () {
      this.currentDate = moment()
      this.dateSelector.nextEnabled = false
      await this.reloadTable()
    },
    async reloadTable () {
      const rangeValue = this.getCurrentRangeValue()
      this.currentRangeDisplay = rangeValue.display
      this.currentRangeValue = rangeValue.value
      const kpiDataResult = await kpiService.getKpiData(this.kpiQueryData.key, this.currentRangeType, rangeValue.value, this.kpiQueryData.top)
      if (kpiDataResult) {
        this.dataResult = kpiDataResult.filter(itm => itm.rangeType === this.currentRangeType)
      }
    },
    getCurrentRangeValue () {
      switch (this.currentRangeType) {
        case 'Day':
          return { value: this.currentDate.format('YYYYMMDD'), display: this.currentDate.format('DD.MM.YYYY') }
        case 'Month':
          return { value: this.currentDate.format('YYYYMM'), display: this.currentDate.format('MM.YYYY') }
        case 'Week':
          return { value: this.currentDate.format('YYYYWW'), display: this.currentDate.format('W YYYY') }
        case 'Year':
          return { value: this.currentDate.format('YYYY'), display: this.currentDate.format('YYYY') }
        default:
          return { value: '0', display: '' }
      }
    },
    async pagingDate (substract) {
      let modifierAction = null
      let isSameComparer = null
      switch (this.currentRangeType) {
        case 'Day':
          modifierAction = 'days'
          isSameComparer = 'day'
          break
        case 'Month':
          modifierAction = 'months'
          isSameComparer = 'month'
          break
        case 'Week':
          modifierAction = 'weeks'
          isSameComparer = 'week'
          break
        case 'Year':
          modifierAction = 'years'
          isSameComparer = 'year'
          break
      }
      if (modifierAction) {
        this.currentDate = substract ? this.currentDate.subtract(1, modifierAction) : this.currentDate.add(1, modifierAction)
        this.dateSelector.nextEnabled = !this.currentDate.isSame(moment(), isSameComparer)
        await this.reloadTable()
      }
    },
    formatValue (val) {
      if (this.valueFormat) {
        return this.valueFormat(val)
      }
      return val
    }
  }
}
</script>
