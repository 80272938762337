<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-auto">{{ $t(title) }}</div>
      <div class="col-auto">
        <b-form-select v-model="currentRangeType" :options="getRangeTypes" @change="dropDownChanged()" />
      </div>
      <div class="col-auto" v-if="currentRangeValue !== '0'">
        <ul class="pagination mb-0">
          <li class="page-item"><a href="#" @click.prevent="pagingDate(true)" class="page-link bg-primary text-white">&laquo;</a></li>
          <li class="page-item"><span class="page-link">{{ currentRangeDisplay }}</span></li>
          <li class="page-item" v-if="dateSelector.nextEnabled"><a href="#" @click.prevent="pagingDate(false)" class="page-link bg-primary text-white">&raquo;</a></li>
        </ul>
      </div>
    </div>

    <VueApexCharts v-if="isLoaded" type="donut" :options="chartOptions" :series="series"></VueApexCharts>
  </div>
</template>

<script>
import kpiService from '@/$plugins/services/kpi-service'
import moment from 'moment'
import 'moment/locale/de'
import VueApexCharts from 'vue-apexcharts'

const rangeTypes = ['Year', 'Month', 'Week', 'Day', 'All']

export default {
  name: 'KpiSalesGraph',
  components: { VueApexCharts },
  props: {
    title: String,
    kpiQueryData: {
      key: String
    },
    valueFormat: Function
  },
  data () {
    return {
      isLoaded: false,
      currentRangeType: 'Week',
      currentRangeValue: '',
      currentRangeDisplay: '',
      currentDate: moment(),
      dateSelector: {
        nextEnabled: false
      },
      chartOptions: {
        chart: {
          id: `chart-${this.kpiQueryData.key}`,
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#612680',
            shadeTo: 'light',
            shadeIntensity: 0.6
          }
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              fontFamily: 'Lato'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: 'Lato'
            }
          }
        },
        noData: {
          text: this.$t('kpi.graph.nodata')
        },
        tooltip: {
          fillSeriesColor: false,
          theme: 'light',
          style: {
            fontFamily: 'Lato'
          },
          y: {
            formatter: (value) => {
              return this.formatValue(value)
            }
          }
        },
        labels: []
      },
      series: []
    }
  },
  async mounted () {
    await this.reloadGraph()
    this.$emit('dataLoaded')
    this.isLoaded = true
  },
  computed: {
    getRangeTypes () {
      return rangeTypes.map(itm => ({ text: this.$t(`kpi.graph.salesxaxis.${itm.toLowerCase()}`), value: itm }))
    }
  },
  methods: {
    async dropDownChanged () {
      this.currentDate = moment()
      this.dateSelector.nextEnabled = false
      await this.reloadGraph()
    },
    async reloadGraph () {
      const rangeValue = this.getCurrentRangeValue()
      this.currentRangeDisplay = rangeValue.display
      this.currentRangeValue = rangeValue.value
      const kpiDataResult = await kpiService.getKpiData(this.kpiQueryData.key, this.currentRangeType, rangeValue.value)
      if (kpiDataResult) {
        const data = kpiDataResult.filter(itm => itm.rangeType === this.currentRangeType)
        const groupedData = data.reduce((result, item) => {
          const { kpiKey, value } = item
          if (!result[kpiKey]) {
            result[kpiKey] = {
              sum: 0,
              count: 0,
              label: null
            }
          }
          result[kpiKey].sum += value
          result[kpiKey].count++
          result[kpiKey].label = result[kpiKey]?.label ?? item.metaData
          return result
        }, {})

        for (const kpiKey in groupedData) {
          groupedData[kpiKey].average = groupedData[kpiKey].sum / groupedData[kpiKey].count
        }
        if (Object.keys(groupedData).length) {
          this.series = Object.values(groupedData).map(itm => itm.average)
          this.chartOptions = { ...this.chartOptions, ...{ labels: Object.keys(groupedData).map(itm => groupedData[itm].label ?? this.$t(`kpi.graph.pie.labels.${itm.split('-').pop()}`)) } }
        } else {
          this.series = []
          this.chartOptions = { ...this.chartOptions, ...{ labels: [] } }
        }
      }
    },
    getCurrentRangeValue () {
      switch (this.currentRangeType) {
        case 'Day':
          return { value: this.currentDate.format('YYYYMMDD'), display: this.currentDate.format('DD.MM.YYYY') }
        case 'Month':
          return { value: this.currentDate.format('YYYYMM'), display: this.currentDate.format('MM.YYYY') }
        case 'Week':
          return { value: this.currentDate.format('YYYYWW'), display: this.currentDate.format('W YYYY') }
        case 'Year':
          return { value: this.currentDate.format('YYYY'), display: this.currentDate.format('YYYY') }
        default:
          return { value: '0', display: '' }
      }
    },
    async pagingDate (substract) {
      let modifierAction = null
      let isSameComparer = null
      switch (this.currentRangeType) {
        case 'Day':
          modifierAction = 'days'
          isSameComparer = 'day'
          break
        case 'Month':
          modifierAction = 'months'
          isSameComparer = 'month'
          break
        case 'Week':
          modifierAction = 'weeks'
          isSameComparer = 'week'
          break
        case 'Year':
          modifierAction = 'years'
          isSameComparer = 'year'
          break
      }
      if (modifierAction) {
        this.currentDate = substract ? this.currentDate.subtract(1, modifierAction) : this.currentDate.add(1, modifierAction)
        this.dateSelector.nextEnabled = !this.currentDate.isSame(moment(), isSameComparer)
        await this.reloadGraph()
      }
    },
    formatValue (val) {
      if (this.valueFormat) {
        return this.valueFormat(val)
      }
      return val
    }
  }
}
</script>
